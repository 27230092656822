@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fade and Slide-Up Animation */
@keyframes fadeSlideUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Apply the animation to elements */
  .animate-fade-slide-up {
    animation: fadeSlideUp 1s ease-out forwards;
  }
  
  .delay-300 {
    animation-delay: 0.2s;
  }
  
